import { RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { loadAppCustomizationsData, selectAppInfo, selectAppPersonalizations } from './services/utils/appInfoSlice';
import { checkUserAuth, selectAppAuth } from "./services/auth/authSlice";
import { ErrorBoundary } from "react-error-boundary";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css'
import './components/Form.css'


//import app router from features
import {router} from './features/Router/appRouter'
import {Div} from "./components/Div";
import { loadGlobalFonts } from "./fonts/fonts";
import { updateOnlineDate } from "./features/User/userSlice";
import ErrorScreen from "./screens/ErrorScreen/ErrorScreen";
import { isMobile } from "react-device-detect";
import { sendTfEvent } from "./utils/utils";


// Ass RouterProvider to the app
export default function App() {

  const dispatch = useDispatch();
  const userAuth = useSelector(selectAppAuth)
  const appInfo = useSelector(selectAppInfo)
  const personalizations = useSelector(selectAppPersonalizations)
  

  useEffect(()=>{
    //load customizations data for tenant
    dispatch(loadAppCustomizationsData()).then(data => {
      loadGlobalFonts()
      document.documentElement.style.setProperty('--primary-color', personalizations?.primary_button?.background_color || '#436094');
      document.documentElement.style.setProperty('--link-color', personalizations?.link?.color || '#436094');
    })
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    //load user data when token available
    if(userAuth.auth_token ){
        dispatch(updateOnlineDate())
    }
    // eslint-disable-next-line
  },[userAuth.auth_token])

  useEffect(()=>{
    //load user data when token available
    if(userAuth.refresh_auth && !userAuth.auth_token){
      dispatch(checkUserAuth())
    }
    console.log('Mobile Device: '+isMobile)

    if(isMobile && appInfo?.is_linked_to_app && !appInfo?.is_ofa)
      sendTfEvent({disableConfirmExit:true})
    // eslint-disable-next-line
  },[])

  return (
    <Div id="App">
      <ToastContainer closeButton={false} hideProgressBar autoClose={3000}/>
      <ErrorBoundary fallback={<ErrorScreen />}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </Div>
  )
}


