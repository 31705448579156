import { useSelector } from "react-redux"
import { selectAppPersonalizations } from "../../../services/utils/appInfoSlice"
import ProgressBar from 'react-bootstrap/ProgressBar';
import { API_URL } from "../../../config/constants";
import tf_logo from '../../../assets/images/logo_tf.svg'



export default function Header(props) {

    const personalizations = useSelector(selectAppPersonalizations)

    return(
        <header>
            <div>
                <img src={personalizations?.logo_url ? `${API_URL}${personalizations?.logo_url}` : tf_logo} />
            </div>
            <ProgressBar now={props?.progress || 0} />
        </header>
    )
}