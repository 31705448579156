export const SaveToLocalStorage = function (key, obj) {
return localStorage.setItem(key, JSON.stringify(obj))
}
export const SaveToSessionStorage = function (key, obj) {
return sessionStorage.setItem(key, JSON.stringify(obj))
}
export const GetFromLocalStorage = function (key) {
return JSON.parse(localStorage.getItem(key))
}
export const GetFromSessionStorage = function (key) {
return JSON.parse(sessionStorage.getItem(key))
}
export const RemoveFromLocalStorage = function (key) {
 return localStorage.removeItem(key)
}
export const RemoveFromSessionStorage = function (key) {
 return sessionStorage.removeItem(key)
}
export const randomUid = function() {
  return String(Math.floor(Math.random() * 9e15))
}
export const useBasePath = (location, params) => {
    return Object.values(params).reduce(
        (path, param) => path.replace('/' + param, ''),
        location.pathname,
    );
};
export const isWebview = () => {
  const navigator = window.navigator
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()
  const standalone = navigator.standalone

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isSafari = /safari/.test(normalizedUserAgent)
  const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)

  return isWebview
}
export const openTfApp = (location) => {
    var obj = { "redirectToAppScreen": location };
    if(window.ReactNativeWebView !== undefined)
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    else if (window.parent !== undefined)
      window.parent.postMessage(JSON.stringify(obj));
    else
      console.log(location)
}
export const sendTfEvent = (event) => {
    if(window.ReactNativeWebView !== undefined)
      window.ReactNativeWebView.postMessage(JSON.stringify(event));
    else if (window.parent !== undefined)
      window.parent.postMessage(JSON.stringify(event));
    else
      console.log(event)
}
export const backToApp = () => {
    sendTfEvent({goBack:true})
}
export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}